import React from 'react';
import { graphql } from 'gatsby';
import Sticky from 'react-stickynode';
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../../lib/helpers';
import BlogPostPreviewList from '../../components/blog-post-preview-list';
import Container from '../../components/container';
import GraphQLErrorList from '../../components/graphqlerrorlist';
import SEO from '../../components/seo';
import Layout from '../../components/layout';

import styled, { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';

import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import Footer from 'common/src/containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: site {
      siteMetadata {
        title
        description
      }
    }
    posts: allSanityPost(
      limit: 99
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      // <Layout>
      <GraphQLErrorList errors={errors} />
      // </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <Layout>
        <SEO
          title={site.siteMetadata.title}
          description={"Resta informato e risparmia leggendo le ultime novità sui POS grazie al blog di SignorPOS."}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Wrapper>
            <Container>
              {postNodes && (
                <BlogPostPreviewList
                  title="Gli ultimi articoli pubblicati"
                  nodes={postNodes}
                  // browseMoreHref='/archive/'
                />
              )}
            </Container>
          </Wrapper>
          <Footer />
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

const Wrapper = styled.section`
  padding: 104px 0 0;
  background: #fafbff;
`;

export default IndexPage;
